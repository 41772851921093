import React, { useState } from 'react'

const GlobalContext = React.createContext()

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false)
  const [videoModalVisible, setVideoModalVisible] = useState(false)
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false)
  const [headerDark, setHeaderDark] = useState(false)
  const [footerDark, setFooterDark] = useState(true)
  const [headerCTA, setHeaderCTA] = useState(false)
  const [hideFooterLogo, setHideFooterLogo] = useState(false)
  const [cartTotal, setCartTotal] = useState(3)
  const [currentPage, setCurrentPage] = useState('home')

  const toggleTheme = () => {
    setThemeDark(!themeDark)
  }

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible)
  }

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas)
  }

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false)
  }

  const goHeaderDark = () => {
    setHeaderDark(true)
  }
  const goHeaderLight = () => {
    setHeaderDark(false)
  }

  const goFooterDark = () => {
    setFooterDark(true)
  }
  const goFooterLight = () => {
    setFooterDark(false)
  }

  const goDisplayHeaderCTA = () => {
    setHeaderCTA(true)
  }

  const goHideHeaderCTA = () => {
    setHeaderCTA(false)
  }

  const goHideFooterLogo = () => {
    setHideFooterLogo(true)
  }

  const goDisplayFooterLogo = () => {
    setHideFooterLogo(false)
  }

  const incCartTotal = () => {
    setCartTotal(cartTotal + 1)
  }

  const decCartTotal = () => {
    setCartTotal(cartTotal - 1)
  }

  return (
    <GlobalContext.Provider
      value={{
        cartTotal,
        currentPage,
        setCurrentPage,
        closeOffCanvas,
        decCartTotal,
        footerDark,
        goDisplayFooterLogo,
        goDisplayHeaderCTA,
        goFooterDark,
        goFooterLight,
        goHeaderDark,
        goHeaderLight,
        goHideFooterLogo,
        goHideHeaderCTA,
        headerCTA,
        headerDark,
        hideFooterLogo,
        incCartTotal,
        themeDark,
        toggleOffCanvas,
        toggleTheme,
        toggleVideoModal,
        videoModalVisible,
        visibleOffCanvas
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { GlobalProvider }
