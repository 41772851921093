// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-bikeindex-js": () => import("./../../../src/pages/project/bikeindex.js" /* webpackChunkName: "component---src-pages-project-bikeindex-js" */),
  "component---src-pages-project-github-js": () => import("./../../../src/pages/project/github.js" /* webpackChunkName: "component---src-pages-project-github-js" */),
  "component---src-pages-project-petco-js": () => import("./../../../src/pages/project/petco.js" /* webpackChunkName: "component---src-pages-project-petco-js" */),
  "component---src-pages-project-quip-js": () => import("./../../../src/pages/project/quip.js" /* webpackChunkName: "component---src-pages-project-quip-js" */),
  "component---src-pages-project-shape-js": () => import("./../../../src/pages/project/shape.js" /* webpackChunkName: "component---src-pages-project-shape-js" */),
  "component---src-pages-project-vive-js": () => import("./../../../src/pages/project/vive.js" /* webpackChunkName: "component---src-pages-project-vive-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

