import React, { useContext } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import GlobalContext from '../../context/GlobalContext'
import { Title, Box } from '../Core'
import Logo from '../Logo'

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`

// const CopyRightArea = styled.div`
//   border-top: ${({ dark, theme }) =>
//     dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

//   padding: 15px 0;
//   p {
//     color: ${({ dark, theme }) =>
//       dark ? theme.colors.lightShade : theme.colors.darkShade};
//     font-size: 13px;
//     font-weight: 300;
//     letter-spacing: -0.41px;
//     line-height: 38px;
//     margin-bottom: 0;
//   }
//   ul {
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     display: inline-flex;
//     a {
//       color: ${({ dark, theme }) =>
//         dark ? theme.colors.light : theme.colors.dark} !important;
//       font-size: 16px;
//       transition: 0.4s;
//       padding: 0 3px;
//       margin: 0 2.5px;
//       &:visited {
//         text-decoration: none;
//       }
//       &:hover {
//         text-decoration: none;
//         color: ${({ theme }) => theme.colors.lightShade} !important;
//       }
//     }
//   }
// `

const Footer = ({ isDark = true }) => {
  const gContext = useContext(GlobalContext)

  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? 'dark' : 'light'}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className='justify-content-center'>
              <Col lg='6' md='6'>
                {!gContext.hideFooterLogo ? (
                  <>
                    <Logo white={isDark} />
                    <TitleStyled
                      color={isDark ? 'lightShade' : 'darkShade'}
                      css={`
                        line-height: 1;
                      `}
                    ></TitleStyled>
                  </>
                ) : (
                  <></>
                )}
              </Col>

              <Col lg='6' md='6' className='mt-5 mt-lg-0'>
                <Row>
                  <Col xs='6' lg='3'></Col>
                  <Col xs='6' lg='3'></Col>
                  <Col xs='6' lg='3'></Col>
                  <Col xs='6' lg='3'>
                    {/* <div className='mb-lg-4'> */}
                    {/*   <TitleStyled */}
                    {/*     variant='card' */}
                    {/*     color={isDark ? 'light' : 'dark'} */}
                    {/*   > */}
                    {/*     sample work */}
                    {/*   </TitleStyled> */}
                    {/*   <UlStyled color={isDark ? 'lightShade' : 'darkShade'}> */}
                    {/*     <li> */}
                    {/*       <a href='/projects'>projects</a> */}
                    {/*     </li> */}
                    {/*     <li> */}
                    {/*       <a href='/coming-soon'>field manuals</a> */}
                    {/*     </li> */}
                    {/*     {/\* section: products *\/} */}
                    {/*   </UlStyled> */}
                    {/* </div> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Footer
