import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const companyName = 'Tensor Conclave'

const StyledSpan = styled.span`
  color: #fff;
  font-size: 24px;
  text-transform: lowercase;
`

const Logo = ({ white, height, className = '', ...rest }) => {
  return (
    <Link to='/' className={`${className}`} {...rest}>
      <StyledSpan>{companyName}</StyledSpan>
    </Link>
  )
}

export default Logo
